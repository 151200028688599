<template>
   <v-card>
      <v-data-table
         :headers="tableHeader"
         :items="tableData"
         item-key="id"
         class="table-rounded"
         disable-sort
         no-data-text="No data"
         loading-text="Loading data..."
         :loading="isFetching"
      >
         <template #[`item.id`]="{ item }" v-if="!noViewBtn">
            <v-btn color="primary" class="px-8" outlined :to="toPage(item.id)">View</v-btn>
         </template>
      </v-data-table>
   </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
   name: "AdminTableView",
   props: ["headers", "data", "detailPage", "noViewBtn"],
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
      tableHeader() {
         let header = this.headers;
         if(!this.noViewBtn) {
            header.push({ text: '', value: 'id', align: 'center', sortable: false });
         }
         return header;
      },
      tableData() {
         if(this.data) {
            let result = this.data;
            result.forEach(row => {
               Object.keys(row).forEach(key => {
                  if(row[key] === '' || row[key] === null || row[key] === undefined) {
                     row[key] = '-'
                  }
               })
            })
            return result;
         }
      },
   },
   methods: {
      toPage(id) {
         if(this.detailPage) {
            return {
               name: this.detailPage,
               params: {
                  id: id
               }
            }
         }
      }
   }
};
</script>
