<template>
   <div>
      <v-row>
         <v-col cols="12" class="mt-10">
            <TableView :data="data" :headers="headers" :noViewBtn="true" />
         </v-col>
      </v-row>
   </div>
</template>

<script>
import dummyData from "@/components/dummy-data"
import { mapActions, mapState } from "vuex";
import TableView from "@/components/TableView"

export default {
   name: 'TradeInSummary',
   components: {
      TableView,
   },
   data() {
      return {
         responseData: [],
         headers: [
            { text: "Category ID", value: "category_no" },
            { text: "Category title", value: "categoryText" },
            { text: "Trade-in inventory (quantity)", value: "quantity" },
         ],
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),

      data() {
         return this.responseData;
      },
   },
   methods: {
      ...mapActions(["sendRequest"], "request"),
      async initData() {
         const resp = await this.sendRequest({ url: '', method: 'GET', params: {} });
         if(resp.data) {
            const categoryList = dummyData.trade_in_setting_list;
            this.responseData = dummyData.trade_in_summary_list.map(item => {
               const searchCategory = categoryList.filter(x => x.id == item.category);
               return {
                  ...item,
                  category_no: searchCategory.length ? searchCategory[0].category_no : '',
                  categoryText: searchCategory.length ? searchCategory[0].title : '',
               }
            });
         }
      },
   },
   async mounted() {
      await this.initData();
   }
}
</script>
