const data = {
  user_level_list: [
    { id: 1, name: 'Normal' },
    { id: 2, name: 'VIP' },
    { id: 3, name: 'Strategic partner' },
  ],
  technician_tag_list: [
    { id: 1, name: 'Junior' },
    { id: 2, name: 'Senior' },
  ],
  service_list: [
    { id: 1, name: '硬件檢查' },
    { id: 2, name: '電腦維修服務' },
    { id: 3, name: '電腦螢幕檢查' },
  ],
  others: {
    upgrade_to_vip: '$2500',
    upgrade_to_senior_technician: '$20',
    credit_ratio: '$50',
    check_list: [
      { id: 1, item: '確認收據' },
      { id: 2, item: '確認voucher' },
      { id: 3, item: '確認產物圖片與實物相符' },
      { id: 4, item: '確認保養內容' },
      { id: 5, item: '螢幕檢查流程 - 電源' },
      { id: 6, item: '螢幕檢查流程 - 3色顯示' },
      { id: 7, item: '螢幕檢查流程 - 3色顯示' },
    ],
    remark: '如果顧客無買voucher，可以提佢買返，買完再scan voucher QR code，咁佢哋都可以有優惠',
    terms: '條款及細則：\n1. 換領券不可以同時與任何其他優惠同時使用，亦不能兌換成現金或任何其他物品。 \n2. 換領券只適用於維修中心開放時間及非休息日，請預先致電相關維修中心查詢開放時間及休息日子並預約服務。 \n3. 維修中心可因應個別情況，調整推廣日期而不作另行通知。 \n4. 如有任何爭議，Foward Professional & Creative Limited保留一切最終決定權，並有權在任何時間終止本推廣或更改任何本條款及細則而毋須向客戶作出任何通知。',
    download_link: '',
  },
  push_noti: {
    title: '最新優惠', content: '7月前註冊帳戶並成功消費，可即升級為成VIP會員', schedule_date: '2020-05-20', schedule_time: '16:29',
  },
  report: {
    voucher_redeem_report: '',
    sales_report: '',
    technician_report: '',
    credit_reward_report: '',
  },
  questionnaire_setting: {
    title: '你可以為此服務人員的專業程度/服務態度評分',
    ranking_from: '0',
    ranking_to: '10',
  },
  questionnaire_record: [
    { id: 1, appt_datetime: '2022-05-10 13:00', username: 'Rex', service: 2, technician: 'Steven', rating: 8 },
    { id: 2, appt_datetime: '2022-05-09 16:00', username: 'Rex', service: 2, technician: 'Steven', rating: 7 },
  ],
  member_list: [
    {
      id: 1,
      username: 'Rex',
      email: 'rexpcboy@gmail.com',
      referralCode: 'UF93K',
      phone: '67709626',
      acc_type: 1,
      credit: '58',
      user_level: 2,
      create_date: '2022-05-09',
      qr_code_url: 'https://www.qr-code-generator.com/wp-content/themes/qr/new_structure/markets/core_market_full/generator/dist/generator/assets/images/websiteQRCode_noFrame.png',
    },
    {
      id: 2,
      username: 'Steven',
      email: 'steven@forward-pc.com',
      referralCode: 'UF93K',
      phone: '67709626',
      acc_type: 2,
      credit: '58',
      user_level: 2,
      create_date: '2022-05-02',
      technician_tag: 2,
      technician_searching_seq: '',
      technician_transport_allowance: false,
      technician_check_tools: false,
      technician_staff_coat: false,
      technician_meal_allowance: false,
      exp_job_title: '電腦維修技工',
      exp_period: 'Jul 2020 - Oct 2021',
      exp_desc: '維修電腦',
      exp_hardware: false,
      exp_software: false,
      exp_monitor: false,
      exp_install: '',
      permit_technician_name: 'Steven So',
      permit_personal_image: '',
      permit_staff_no: 'FW10001',
      permit_contract_no: '62231703',
      permit_position: 'Senior technician',
      permit_contract_and_terms: '',
      service_amount: '4',
      average_ranking: '9',
      qr_code_url: 'https://www.qr-code-generator.com/wp-content/themes/qr/new_structure/markets/core_market_full/generator/dist/generator/assets/images/websiteQRCode_noFrame.png',
    },
    {
      id: 3,
      username: 'Chrysan',
      email: 'chrysan2022hk@gmail.com',
      referralCode: 'OWE34',
      phone: '62231703',
      acc_type: 3,
      credit: '58',
      user_level: 2,
      create_date: '2022-04-25',
      qr_code_url: 'https://www.qr-code-generator.com/wp-content/themes/qr/new_structure/markets/core_market_full/generator/dist/generator/assets/images/websiteQRCode_noFrame.png',
    },
  ],
  acc_type_list: [
    { id: 1, name: 'Regular' },
    { id: 2, name: 'Technician' },
    { id: 3, name: 'Commercial' },
  ],
  job_list: [
    {id: 1, job_no: '001', create_datetime: '2022-05-19 10:30:00', username: 'Rex', email: 'rexpcboy@gmail.com', service: 2, appt_datetime: '2022-06-29 14:00', address: '觀塘成業街日昇中心地下405室', technician: 2, amount: 0, is_voucher_used: true, report_url: 'https://123.pdf', status: 'COMPLETED' },
    {id: 2, job_no: '002', create_datetime: '2022-05-10 10:55:00', username: 'Rex', email: 'rexpcboy@gmail.com', service: 2, appt_datetime: '2022-05-10 13:00', address: '觀塘成業街日昇中心地下405室', technician: 2, amount: 0, is_voucher_used: true, report_url: 'https://123.pdf', status: 'COMPLETED' },
    {id: 3, job_no: '003', create_datetime: '2022-05-09 15:26:00', username: 'Rex', email: 'rexpcboy@gmail.com', service: 2, appt_datetime: '2022-05-09 16:00', address: '觀塘成業街日昇中心地下405室', technician: 2, amount: 0, is_voucher_used: true, report_url: 'https://123.pdf', status: 'COMPLETED' },
    {id: 4, job_no: '004', create_datetime: '2022-05-08 11:09:00', username: 'Rex', email: 'rexpcboy@gmail.com', service: 1, appt_datetime: '2022-05-09 11:00', address: '觀塘成業街日昇中心地下405室', technician: 2, amount: 0, is_voucher_used: true, report_url: 'https://123.pdf', status: 'COMPLETED' },
    {id: 5, job_no: '005', create_datetime: '2022-05-08 11:01:00', username: 'Chrysan', email: 'chrysan2022hk@gmail.com', service: 3, appt_datetime: '2022-05-25 10:30', address: '觀塘成業街日昇中心地下405室', technician: 2, amount: 0, is_voucher_used: false, report_url: 'https://123.pdf', status: 'PENDING' },
    {id: 6, job_no: '006', create_datetime: '2022-05-08 10:51:00', username: 'Chrysan', email: 'chrysan2022hk@gmail.com', service: 1, appt_datetime: '2022-05-08 11:30', address: '觀塘成業街日昇中心地下405室', technician: 2, amount: 0, is_voucher_used: true, report_url: 'https://123.pdf', status: 'COMPLETED' },
  ],
  voucher_setting_list: [
    {id: 1, service_no: '010', title: '電腦配件'},
    {id: 2, service_no: '009', title: '手提電腦配件'},
  ],
  voucher_category_list: [
    { id: 1, title: '硬件檢查服務' },
    { id: 2, title: '電腦維修服務' },
    { id: 3, title: '電腦螢幕檢查' },
  ],
  voucher_package_list: [
    { id: 1, voucher_no: '008', voucher_title: '電腦維修服務', price: 1000, price_vip: 900, status: 'ACTIVE', active_day: 30, desc: '條款及細則：\n1. 換領券不可以同時與任何其他優惠同時使用，亦不能兌換成現金或任何其他物品。 \n2. 換領券只適用於維修中心開放時間及非休息日，請預先致電相關維修中心查詢開放時間及休息日子並預約服務。 \n3. 維修中心可因應個別情況，調整推廣日期而不作另行通知。 \n4. 如有任何爭議，Foward Professional & Creative Limited保留一切最終決定權，並有權在任何時間終止本推廣或更改任何本條款及細則而毋須向客戶作出任何通知。', qr_code_url: 'https://www.qr-code-generator.com/wp-content/themes/qr/new_structure/markets/core_market_full/generator/dist/generator/assets/images/websiteQRCode_noFrame.png', service_item_list: [
      { id: 1, service: 1, quantity: 5, },
    ] },
    { id: 2, voucher_no: '007', voucher_title: '硬件檢查服務', price: 100, price_vip: 90, status: 'ACTIVE', active_day: 30, desc: '條款及細則：\n1. 換領券不可以同時與任何其他優惠同時使用，亦不能兌換成現金或任何其他物品。 \n2. 換領券只適用於維修中心開放時間及非休息日，請預先致電相關維修中心查詢開放時間及休息日子並預約服務。 \n3. 維修中心可因應個別情況，調整推廣日期而不作另行通知。 \n4. 如有任何爭議，Foward Professional & Creative Limited保留一切最終決定權，並有權在任何時間終止本推廣或更改任何本條款及細則而毋須向客戶作出任何通知。', qr_code_url: 'https://www.qr-code-generator.com/wp-content/themes/qr/new_structure/markets/core_market_full/generator/dist/generator/assets/images/websiteQRCode_noFrame.png', service_item_list: [
      { id: 1, service: 1, quantity: 5, },
    ] },
    { id: 3, voucher_no: '003', voucher_title: '電腦螢幕檢查', price: 90, price_vip: 81, status: 'ACTIVE', active_day: 30, desc: '條款及細則：\n1. 換領券不可以同時與任何其他優惠同時使用，亦不能兌換成現金或任何其他物品。 \n2. 換領券只適用於維修中心開放時間及非休息日，請預先致電相關維修中心查詢開放時間及休息日子並預約服務。 \n3. 維修中心可因應個別情況，調整推廣日期而不作另行通知。 \n4. 如有任何爭議，Foward Professional & Creative Limited保留一切最終決定權，並有權在任何時間終止本推廣或更改任何本條款及細則而毋須向客戶作出任何通知。', qr_code_url: 'https://www.qr-code-generator.com/wp-content/themes/qr/new_structure/markets/core_market_full/generator/dist/generator/assets/images/websiteQRCode_noFrame.png', service_item_list: [
      { id: 1, service: 1, quantity: 5, },
    ] },
  ],
  redeem_category_list: [
    { id: 1, category_no: '010', title: '電腦配件', },
    { id: 2, category_no: '009', title: '手提電腦配件', },
  ],
  redeem_item_list: [
    { id: 1, item_no: '014', title: 'PNY WavePNY AttachR USB3.2 128GB USB Drive', redeem_category: 1, credit_amount: 50, desc: '3合1設計: Type-C PD輸入, HDMI輸出, USB 3.0\n支援Switch Dock模式，可取代原裝Dock\n同時支援手機/平板/Macbook等\n支援高速65W PD Type-C充電\n支援 HDMI 4K 30Hz UHD輸出(Switch只支援1080P)\n高速檔案傳輸，最大5GBps*\n支援最大2TB USB記憶體', status: 'ACTIVE', },
  ],
  redeem_record_list: [
    { id: 1, redeem_no: '001', create_datetime: '2022-05-15 16:49:00', username: 'Rex', email: 'rexpcboy@gmail.com', item: 'Xpower XP-RTT05 3-in-1 Switch Hubs', credit_amount: 50, redeem_method: 1, status: 'COMPLETED', },
  ],
  redeem_method_list: [
    { id: 1, name: 'Pickup' },
  ],
  trade_in_setting_list: [
    {id: 1, category_no: '003', title: '電腦硬件'},
    {id: 2, category_no: '002', title: '手提電腦'},
  ],
  trade_in_record_list: [
    {id: 1, trade_in_no: '006', username: 'Rex', create_datetime: '2022-05-14 11:44', category: 1, price: 40, receipt_photo_url: 'https://123.jpg', item_photo_url:'https://123.jpg', status: "ACCEPT"},
    {id: 2, trade_in_no: '005', username: 'Rex', create_datetime: '2022-05-14 10:05', category: 1, price: 40, receipt_photo_url: 'https://123.jpg', item_photo_url:'https://123.jpg', status: "ACCEPT"},
    {id: 3, trade_in_no: '004', username: 'Rex', create_datetime: '2022-05-16 16:28', category: 1, price: 40, receipt_photo_url: 'https://123.jpg', item_photo_url:'https://123.jpg', status: "REJECT"},
  ],
  trade_in_summary_list: [
    { id: 1, category: 1, quantity: 2 },
    { id: 2, category: 2, quantity: 0 },
  ],
  order_list: [
    { 
      id: 1, 
      create_datetime: '2022-05-10 09:27',
      username: 'Rex',
      email: 'rexpcboy@gmail.com',
      package: 1,
      amount: '900',
      credit_earned: 18,
      transfer_slip_photo_url: 'https://i.picsum.photos/id/11/500/300.jpg?hmac=X_37MM-ameg7HWL6TKJT2h_5_rGle7IGN_CUdEDxsAQ',
      status: 'WAITING',
    },
  ],
  banner: {
    img_url: 'https://i.picsum.photos/id/11/500/300.jpg?hmac=X_37MM-ameg7HWL6TKJT2h_5_rGle7IGN_CUdEDxsAQ'
  }
}

export default data
